export enum TYPE_FILE {
  PDF = 'application/pdf',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  MP4 = 'video/mp4',
  CSV = 'text/csv',
  TXT = 'text/plain'
}

export enum AttachmentColEnum {
  NAME = 'originalName',
  TYPE = 'type',
  POLICY = 'policy',
  DESCRIPTION = 'description',
  UPLOADED_BY = 'addBy',
  CREATED_AT = 'createdAt'
}
